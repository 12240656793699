import { UITheme } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

import { light } from './light';

export const dark: UITheme.Interface = {
  ...light,
  theme: 'dark',
  primary: getColor('green'),
  text: getColor('white'),
  background: getColor('gray'),
};
