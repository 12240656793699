import { UIIcon } from '@klappir/types';
export const QuoteIcon = (props: UIIcon.SVGProps) => {
  const width = props.width ?? '24';
  const height = props.height ?? '16';
  const color = props.color ?? '#3C9E76';
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.37895 16C2.84444 16 1.72164 15.6444 1.01053 14.9333C0.336842 14.1848 0 13.2678 0 12.1825V11.1158C0 10.1053 0.149708 9.09474 0.449123 8.08421C0.748538 7.03626 1.14152 6.02573 1.62807 5.05263C2.15205 4.07953 2.76959 3.16257 3.4807 2.30175C4.19181 1.44093 4.95906 0.673682 5.78246 0H10.6105C9.41287 1.16023 8.40234 2.30175 7.57895 3.42456C6.75556 4.54737 6.13801 5.80117 5.72632 7.18596C6.8117 7.41053 7.59766 7.87836 8.08421 8.58947C8.57076 9.26316 8.81404 10.0491 8.81404 10.9474V12.1825C8.81404 13.2678 8.45848 14.1848 7.74737 14.9333C7.07368 15.6444 5.95088 16 4.37895 16ZM17.0105 16C15.476 16 14.3532 15.6444 13.6421 14.9333C12.9684 14.1848 12.6316 13.2678 12.6316 12.1825V11.1158C12.6316 10.1053 12.7813 9.09474 13.0807 8.08421C13.3801 7.03626 13.7731 6.02573 14.2596 5.05263C14.7836 4.07953 15.4012 3.16257 16.1123 2.30175C16.8234 1.44093 17.5906 0.673682 18.414 0H23.2421C22.0444 1.16023 21.0339 2.30175 20.2105 3.42456C19.3871 4.54737 18.7696 5.80117 18.3579 7.18596C19.4433 7.41053 20.2292 7.87836 20.7158 8.58947C21.2023 9.26316 21.4456 10.0491 21.4456 10.9474V12.1825C21.4456 13.2678 21.0901 14.1848 20.3789 14.9333C19.7053 15.6444 18.5825 16 17.0105 16Z"
        fill={color}
      />
    </svg>
  );
};
