import React, { useEffect, useRef } from 'react';

import { UIBrand } from '@klappir/types';
import { generateLine } from '@klappir/util/svg';

import { getColor } from '../colors';

export const Line = ({ className, style, ...props }: UIBrand.LineProps) => {
  const svgEl = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (svgEl?.current) {
      generateLine(svgEl.current, {
        ...props,
        color: props?.customColor ?? getColor(props?.color || 'green'),
      });
    }
  }, [props, svgEl]);

  return <svg ref={svgEl} className={className || undefined} style={style} />;
};
