import { css } from '@klappir/vendor/styled';

export const boxSizingReset = css`
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;
