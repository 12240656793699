import { UIIcon } from '@klappir/types';

export const LinkedInIcon = (props: UIIcon.SVGProps) => {
  const width = props.width ?? '24';
  const height = props.height ?? '24';
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_194_428)">
        <path
          d="M1.36002 8.08008H5.84002C5.84002 8.08008 6.40002 8.08008 6.40002 8.64008V23.2001C6.40002 23.2001 6.40002 23.7601 5.84002 23.7601H1.36002C1.36002 23.7601 0.800018 23.7601 0.800018 23.2001V8.64008C0.800018 8.64008 0.800018 8.08008 1.36002 8.08008Z"
          fill="#3C9E76"
        />
        <path
          d="M3.57761 5.84018C5.73301 5.85738 7.09881 3.53498 6.03601 1.65968C4.97321 -0.215524 2.27901 -0.237124 1.18641 1.62088C0.933411 2.05108 0.800011 2.54108 0.800011 3.04018C0.793811 4.58038 2.03741 5.83398 3.57761 5.84018Z"
          fill="#3C9E76"
        />
        <path
          d="M19.28 23.7601H22.64C22.9493 23.7601 23.2 23.5094 23.2 23.2001V13.7921C23.2 9.56969 20.8144 7.52009 17.4768 7.52009C16.1402 7.47249 14.8461 7.99339 13.9152 8.95369C13.7868 9.09299 13.5833 9.13379 13.4112 9.05449C13.2379 8.98969 13.1222 8.82509 13.12 8.64009C13.12 8.33079 12.8693 8.08009 12.56 8.08009H9.20001C8.89071 8.08009 8.64001 8.33079 8.64001 8.64009V23.2001C8.64001 23.5094 8.89071 23.7601 9.20001 23.7601H12.56C12.8693 23.7601 13.12 23.5094 13.12 23.2001V14.8001C13.12 12.6447 15.4533 11.2975 17.32 12.3752C18.1863 12.8754 18.72 13.7998 18.72 14.8001V23.2001C18.72 23.5094 18.9707 23.7601 19.28 23.7601Z"
          fill="#3C9E76"
        />
      </g>
      <defs>
        <clipPath id="clip0_194_428">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
