import { UIText } from '@klappir/types';
import { getColor, resolveColor } from '@klappir/ui/brand';
import { easings, fonts } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

export const Link = styled.a<UIText.LinkProps>`
  ${({ color, hoverColor }) => css`
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    z-index: 1;

    font-weight: ${fonts.weight.semibold};
    color: ${resolveColor(color || 'green')};
    text-decoration: none;

    transition: color 0.2s;

    ${hoverColor
      ? css`
          &:hover {
            color: ${resolveColor(hoverColor)};
          }
        `
      : css`
          &:before {
            content: ' ';
            display: block;

            position: absolute;
            z-index: -1;
            top: -0.1em;
            right: -0.2em;
            bottom: -0.1em;
            left: -0.2em;

            background: ${getColor('green', 20)};
            border-radius: 3px;

            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.2s ${easings.easeOutCubic};
          }

          &:hover {
            /* color: ${getColor('white')}; */
            transition-duration: 0.4s;
            /* transition-delay: 0.5s; */

            &:before {
              transform: scaleX(1);
              transform-origin: bottom left;

              transition-duration: 0.4s;
            }
          }
        `}
  `}
`;
