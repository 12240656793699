import { timingFunctions } from 'polished';

export const easeInBack = timingFunctions('easeInBack');
export const easeInCirc = timingFunctions('easeInCirc');
export const easeInCubic = timingFunctions('easeInCubic');
export const easeInExpo = timingFunctions('easeInExpo');
export const easeInQuad = timingFunctions('easeInQuad');
export const easeInQuart = timingFunctions('easeInQuart');
export const easeInQuint = timingFunctions('easeInQuint');
export const easeInSine = timingFunctions('easeInSine');
export const easeOutBack = timingFunctions('easeOutBack');
export const easeOutCubic = timingFunctions('easeOutCubic');
export const easeOutCirc = timingFunctions('easeOutCirc');
export const easeOutExpo = timingFunctions('easeOutExpo');
export const easeOutQuad = timingFunctions('easeOutQuad');
export const easeOutQuart = timingFunctions('easeOutQuart');
export const easeOutQuint = timingFunctions('easeOutQuint');
export const easeOutSine = timingFunctions('easeOutSine');
export const easeInOutBack = timingFunctions('easeInOutBack');
export const easeInOutCirc = timingFunctions('easeInOutCirc');
export const easeInOutCubic = timingFunctions('easeInOutCubic');
export const easeInOutExpo = timingFunctions('easeInOutExpo');
export const easeInOutQuad = timingFunctions('easeInOutQuad');
export const easeInOutQuart = timingFunctions('easeInOutQuart');
export const easeInOutQuint = timingFunctions('easeInOutQuint');
export const easeInOutSine = timingFunctions('easeInOutSine');
