import { UtilStyles } from '@klappir/types';

export const breakpointsArray: UtilStyles.BreakpointsArray = [
  'tiny',
  'mobile',
  'sm',
  'md',
  'lg',
  'xl',
  'big',
  'huge',
];
export const breakpoints: UtilStyles.BreakpointConfig = {
  tiny: {
    min: '0px',
    max: '399px',
  },
  mobile: {
    min: '400px',
    max: '599px',
  },
  sm: {
    min: '600px',
    max: '799px',
  },
  md: {
    min: '800px',
    max: '999px',
  },
  lg: {
    min: '1000px',
    max: '1199px',
  },
  xl: {
    min: '1200px',
    max: '1399px',
  },
  big: {
    min: '1400px',
    max: '1599px',
  },
  huge: {
    min: '1600px',
    max: '2560px',
  },
};
