import React from 'react';

import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Login = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>login</title>
    <circle className="fill-secondary" fill="" cx="11" cy="12" r="8" />
    <g className="bounce-left">
      <line className="stroke" x1="23.25" y1="12.004" x2="7.5" y2="12.004" />
      <polyline
        className="stroke"
        points="11.25 15.754 7.5 12.004 11.25 8.254"
      />
    </g>
    <path className="stroke" d="M20.693,16.6a10.5,10.5,0,1,1-.179-9.542" />
  </StyledIcon>
);
