import { transparentize } from 'polished';
import React from 'react';

import { UIBrand } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { fonts, useViewport } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { Column } from './Column';
import { gridConfig } from './constants';
import { Container, ContainerProps } from './Container';
import { Row } from './Row';

export interface GridOverlayProps {
  color?: UIBrand.ColorName;
}

const OverlayContainer = styled(Container)`
  pointer-events: none;
  user-select: none;

  overflow: hidden;
  position: fixed;
  z-index: 9999998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  margin: 0;

  ${Column} {
    height: 100vh;
  }
`;

const ColumnFiller = styled.div<GridOverlayProps>`
  ${({ color = 'green' }) => css`
    & {
      box-shadow: 0 0 0 1px ${getColor(color, 40, 10)};
      background-color: ${getColor(color, 20, 5)};
    }

    ::after {
      color: ${getColor(color, 60, 20)};
    }
  `}

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin: 0;
  padding: 4rem 0.5rem;
  width: 100%;
  height: 100%;

  background-color: transparent;

  ::after {
    content: attr(data-column);
    display: block;

    text-align: center;
    font-family: ${fonts.secondaryFont};
    font-weight: ${fonts.weight.bold};
    font-size: 3vw;
  }
`;

export const GridOverlay: React.FunctionComponent<
  GridOverlayProps & ContainerProps
> = ({ color, ...containerProps }) => {
  const viewport = useViewport();

  const columns = gridConfig.columns[viewport];
  return (
    <OverlayContainer {...containerProps}>
      <Row>
        {Array.from({ length: columns }).map((c, key) => (
          <Column key={key} tiny={1}>
            <ColumnFiller color={color} data-column={key + 1} />
          </Column>
        ))}
      </Row>
    </OverlayContainer>
  );
};
