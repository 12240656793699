import Markdown from 'markdown-to-jsx';
import React from 'react';

import { UIText } from '@klappir/types';
import { Image } from '@klappir/ui/media';

import { Code } from './Code';
import { H1, H2, H3, H4, H5 } from './Heading';
import { Link } from './Link';
import { Paragraph } from './Paragraph';

export const RenderMarkdown: React.FC<UIText.RenderMarkdownProps> = ({
  children,
  className,
  overrides,
  ...props
}) => (
  <Markdown
    className={className}
    options={{
      ...(props || {}),
      overrides: {
        p: { component: Paragraph },
        a: { component: Link },
        h1: { component: H1 },
        h2: { component: H2 },
        h3: { component: H3 },
        h4: { component: H4 },
        h5: { component: H5 },
        img: { component: Image },
        code: { component: Code },
        ...(overrides || {}),
      },
    }}
  >
    {children}
  </Markdown>
);
