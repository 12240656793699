import { darken, saturate } from 'polished';

import { UITheme } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const light: UITheme.Interface = {
  theme: 'light',
  primary: getColor('green'),
  text: getColor('black'),
  background: getColor('white'),

  button: {
    primary: {
      default: {
        text: getColor('white'),
        primaryColor: getColor('green'),
        loadingColor: getColor('white'),
        focusColor: getColor('green'),
        focusText: getColor('gray'),
      },
      destructive: {
        text: getColor('white'),
        primaryColor: getColor('error'),
        loadingColor: getColor('white'),
        focusColor: saturate(0.8, getColor('error', 80)),
        focusText: getColor('white'),
      },
      action: {
        text: getColor('white'),
        primaryColor: getColor('blue'),
        loadingColor: getColor('white'),
        focusColor: darken(0.1, getColor('blue')),
        focusText: getColor('white'),
      },
    },
    outline: {
      default: {
        text: getColor('green'),
        primaryColor: getColor('green'),
        loadingColor: getColor('green'),
        focusColor: getColor('green'),
        focusText: getColor('gray'),
      },
      destructive: {
        text: getColor('error'),
        primaryColor: getColor('error'),
        loadingColor: getColor('error'),
        focusColor: saturate(0.8, getColor('error', 80)),
        focusText: getColor('white'),
      },
      action: {
        text: getColor('blue'),
        primaryColor: getColor('blue'),
        loadingColor: getColor('blue'),
        focusColor: darken(0.1, getColor('blue')),
        focusText: getColor('white'),
      },
    },
    text: {
      default: {
        text: getColor('green'),
        primaryColor: getColor('green'),
        loadingColor: getColor('green'),
        focusColor: getColor('green'),
        focusText: getColor('gray'),
      },
      destructive: {
        text: getColor('error'),
        primaryColor: getColor('error'),
        loadingColor: getColor('error'),
        focusColor: saturate(0.8, getColor('error', 80)),
        focusText: getColor('white'),
      },
      action: {
        text: getColor('blue'),
        primaryColor: getColor('blue'),
        loadingColor: getColor('blue'),
        focusColor: darken(0.1, getColor('blue')),
        focusText: getColor('white'),
      },
    },
    icon: {
      default: {
        text: getColor('green'),
        primaryColor: getColor('green'),
        loadingColor: getColor('green'),
        focusColor: getColor('green'),
        focusText: getColor('gray'),
        iconColor: getColor('green'),
      },
      destructive: {
        text: getColor('error'),
        primaryColor: getColor('error'),
        loadingColor: getColor('error'),
        focusColor: saturate(0.8, getColor('error', 80)),
        focusText: getColor('white'),
        iconColor: getColor('error'),
      },
      action: {
        text: getColor('blue'),
        primaryColor: getColor('blue'),
        loadingColor: getColor('blue'),
        focusColor: darken(0.1, getColor('blue')),
        focusText: getColor('white'),
        iconColor: getColor('blue'),
      },
    },
  },
};
