import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const ReduceRisk = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <path
      d="M16.7819 19.3926C16.8249 19.3926 16.8669 19.4053 16.9027 19.4292C16.9384 19.4531 16.9663 19.4871 16.9827 19.5268C16.9992 19.5665 17.0035 19.6102 16.9951 19.6524C16.9867 19.6946 16.966 19.7333 16.9356 19.7637C16.9052 19.7941 16.8665 19.8148 16.8243 19.8232C16.7821 19.8316 16.7384 19.8273 16.6987 19.8109C16.6589 19.7944 16.625 19.7665 16.6011 19.7308C16.5772 19.695 16.5645 19.653 16.5645 19.61C16.5645 19.5523 16.5874 19.497 16.6281 19.4563C16.6689 19.4155 16.7242 19.3926 16.7819 19.3926Z"
      className="stroke thin"
    />
    <path d="M16.7871 17.6539V15.0449" className="stroke thin" />
    <path
      d="M17.5094 12.4351C17.4389 12.303 17.3339 12.1926 17.2055 12.1157C17.0772 12.0387 16.9303 11.998 16.7806 11.998C16.631 11.998 16.4841 12.0387 16.3558 12.1157C16.2274 12.1926 16.1224 12.303 16.0519 12.4351L11.6548 20.8142C11.5899 20.9377 11.5582 21.0759 11.563 21.2152C11.5677 21.3546 11.6086 21.4904 11.6818 21.6091C11.7559 21.7285 11.8593 21.8269 11.9821 21.8951C12.1049 21.9633 12.2431 21.9989 12.3836 21.9987H21.1776C21.3181 21.9989 21.4563 21.9633 21.5792 21.8951C21.702 21.8269 21.8054 21.7285 21.8795 21.6091C21.9526 21.4904 21.9936 21.3546 21.9983 21.2152C22.003 21.0759 21.9714 20.9377 21.9064 20.8142L17.5094 12.4351Z"
      className="stroke thin"
    />
    <path
      d="M8.08763 22.0019H2V9.82669C2 9.59604 2.09162 9.37484 2.25472 9.21175C2.41781 9.04866 2.63901 8.95703 2.86966 8.95703H7.21797C7.44862 8.95703 7.66982 9.04866 7.83291 9.21175C7.996 9.37484 8.08763 9.59604 8.08763 9.82669V22.0019Z"
      className="stroke thin"
    />
    <path
      d="M7.21875 8.95761V5.7616C7.21896 5.67718 7.24375 5.59465 7.29007 5.52407C7.3364 5.4535 7.40227 5.39794 7.47965 5.36417L14.4369 2.27165C14.503 2.24281 14.5752 2.23086 14.6471 2.23686C14.719 2.24287 14.7882 2.26666 14.8486 2.30608C14.909 2.3455 14.9586 2.39931 14.993 2.46268C15.0274 2.52605 15.0455 2.59698 15.0457 2.66909V10.6969"
      className="stroke thin"
    />
    <path
      d="M6.34922 22.0016V19.8274C6.34922 19.7121 6.30341 19.6015 6.22186 19.5199C6.14031 19.4384 6.02971 19.3926 5.91439 19.3926H4.17506C4.05974 19.3926 3.94914 19.4384 3.86759 19.5199C3.78605 19.6015 3.74023 19.7121 3.74023 19.8274V22.0016"
      className="stroke thin"
    />
    <path d="M2 11.5664H5.47864" className="stroke thin" />
    <path d="M12.4375 6.34863H15.0465" className="stroke thin" />
    <path d="M10.6973 8.08789H15.0456" className="stroke thin" />
    <path d="M2 13.3057H3.73932" className="stroke thin" />
    <path d="M8.95703 4.70552V2" className="stroke thin" />
    <path d="M8.08594 22.002H9.82526" className="stroke thin" />
  </StyledIcon>
);
