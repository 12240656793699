import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Twitter = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>social-media-twitter</title>
      <path
        className="stroke"
        d="M23.18,6.71l-2.1-.9,1.1-2.4-2.56.85a4.47,4.47,0,0,0-3-1.2,4.51,4.51,0,0,0-4.5,4.5v1c-3.54.73-6.63-1.2-9.5-4.5q-.75,4,1.5,6L.83,9.56a4.55,4.55,0,0,0,4.25,4l-2.75,1c1,2,2.82,2.31,5.25,2.5a11.52,11.52,0,0,1-6.75,2c12.76,5.67,20.25-2.66,20.25-10V8.23Z"
      />
    </svg>
  </StyledIcon>
);
