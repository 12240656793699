/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import { UtilStyles } from '@klappir/types';
import { media } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { gridConfig } from './constants';

interface ColumnProps {
  /** sets the number of columns for tiny breakpoint */
  tiny?: number;
  /** sets the number of columns for mobile breakpoint */
  mobile?: number;
  /** sets the number of columns for sm breakpoint */
  sm?: number;
  /** sets the number of columns for md breakpoint */
  md?: number;
  /** sets the number of columns for lg breakpoint */
  lg?: number;
  /** sets the number of columns for xl breakpoint */
  xl?: number;
  /** sets the number of columns for big breakpoint */
  big?: number;
  /** sets the number of columns for huge breakpoint */
  huge?: number;
  /** sets the number of the offset columns */
  offset?: number | { [key in UtilStyles.Breakpoint]?: number };
  /** reverse the direction of the column */
  reverse?: boolean | UtilStyles.Breakpoint[];
  /** removes the gutter */
  noGutter?: boolean;
  /** enables debugging */
  debug?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Column = styled(({ children, className }: ColumnProps) => (
  <div className={className}>{children}</div>
))`
  box-sizing: border-box;
  max-width: 100%;
  // flex: 1 0 auto;
  // display: flex;
  // flex-direction: column;

  ${({ noGutter }) =>
    !noGutter &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${(props) => css`
    ${media.map(
      // NOTE: ts-ignore because strictNullCheck
      (bp) => media[bp].above`
        ${
          props[bp] &&
          `
          flex: 1 1 ${
            // @ts-ignore
            (props[bp] / gridConfig.columns[bp]) * 100
          }%;
          max-width: ${
            // @ts-ignore
            (props[bp] / gridConfig.columns[bp]) * 100
          }%;
        `
        }
      `
    )}
  `}

  ${({ offset }) =>
    offset &&
    css`
      ${media.map(
        (bp) => media[bp].above`
          ${
            typeof offset === 'object'
              ? typeof offset[bp] === 'number' &&
                // NOTE: ts-ignore because typescript thinks offset[bp] can be undefined
                // @ts-ignore
                `margin-left: ${
                  offset[bp] > 0
                    ? (offset[bp] / gridConfig.columns[bp]) * 100
                    : 0
                }%;`
              : `margin-left: ${(offset / gridConfig.columns[bp]) * 100}%;`
          }
        `
      )}
    `}

  ${({ reverse }) =>
    reverse &&
    css`
      ${Array.isArray(reverse)
        ? media.map(
            (bp) => media[bp].above`
            flex-direction: ${
              reverse.includes(bp) ? 'column-reverse' : 'column'
            };
          `
          )
        : 'flex-direction: column-reverse;'}
    `}

  ${({ debug }) =>
    debug &&
    css`
      background-color: green;
      outline: white dashed 1px;
    `};
`;
