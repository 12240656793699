import { UICore } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { slantedBottom, slantedTop } from './slant';

export const Section = styled.section<UICore.Section.Props>`
  ${({ slant, backgroundColor, textColor }) => css`
    ${slant === 'top'
      ? slantedTop(backgroundColor ? getColor(backgroundColor) : '#fafafa')
      : slant === 'bottom'
      ? slantedBottom(backgroundColor ? getColor(backgroundColor) : '#fafafa')
      : ''}

    position: relative;
    z-index: 1;

    padding: ${rem(24)};
    background: ${backgroundColor ? getColor(backgroundColor) : '#fafafa'};

    color: ${getColor(textColor || 'gray')};
  `}
`;
