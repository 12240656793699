import { UIIcon } from '@klappir/types';
import { keyframes } from '@klappir/util/styles';
import { css } from '@klappir/vendor/styled';

export const iconAnimations = (icon: UIIcon.IconName, animValue?: string) => {
  switch (icon) {
    case 'arrow-up':
      return css`
        .bounce-up {
          animation: ${animValue ?? '1.1s infinite linear'};
          animation-name: ${keyframes.bounceUp};
        }
      `;

    case 'arrow-down':
      return css`
        .bounce-down {
          animation: ${animValue ?? '1.1s infinite linear'};
          animation-name: ${keyframes.bounceDown};
        }
      `;

    case 'arrow-left':
    case 'login':
      return css`
        .bounce-left {
          animation: ${animValue ?? '1.1s infinite linear'};
          animation-name: ${keyframes.bounceLeft};
        }
      `;

    case 'arrow-right':
    case 'logout':
      return css`
        .bounce-right {
          animation: ${animValue ?? '1.1s infinite linear'};
          animation-name: ${keyframes.bounceRight};
        }
      `;

    default:
      return css``;
  }
};
