import React from 'react';

import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Logout = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>logout</title>
    <circle className="fill-secondary" fill="" cx="13" cy="12" r="8" />

    <g className="bounce-right">
      <line className="stroke" x1="0.75" y1="12.004" x2="16.5" y2="12.004" />
      <polyline
        className="stroke"
        points="12.75 15.754 16.5 12.004 12.75 8.254"
      />
    </g>
    <path className="stroke" d="M3.306,16.6a10.5,10.5,0,1,0,.179-9.542" />
  </StyledIcon>
);
