import React from 'react';

import { DataGraphcms, UIMedia } from '@klappir/types';
import styled, { css } from '@klappir/vendor/styled';

const StyledImageCollage = styled.div<UIMedia.ImageCollageProps>`
  ${({ images }) => css`
    display: grid;
    margin: 0;
    padding: 0;
    width: 100%;
    grid-template-columns: ${images.length > 1
      ? `8vw 28vw calc(4vw + 16px) auto calc(4vw + 16px) calc(4vw + 16px) 33vw`
      : `auto`};
    grid-template-rows: ${images.length === 1
      ? `40vw`
      : images.length === 2
      ? `0 32vw 10vw`
      : images.length === 3
      ? `0 32vw 10vw 4vw`
      : images.length === 4
      ? `0 32vw 10vw 4vw 0 32vw`
      : `0 32vw 10vw 4vw 0 32vw 0`};
    grid-column-gap: 0vw;
    grid-row-gap: calc(4vw + 16px);

    ${images.length === 1
      ? css`
          grid-template-areas: 'image1';
        `
      : images.length === 2
      ? css`
          grid-template-areas:
            'image1 image1 image1 image1 . . . '
            'image1 image1 image1 image1 . . image2'
            '. . . . . . image2 ';
        `
      : images.length === 3
      ? css`
          grid-template-areas:
            'image1 image1 image1 image1 . . . '
            'image1 image1 image1 image1 . . image2'
            '. image3 image3 image3 image3 . image2 '
            '. image3 image3 image3 image3 . . ';
        `
      : images.length === 4
      ? css`
          grid-template-areas:
            'image1 image1 image1 image1 . . . '
            'image1 image1 image1 image1 . . image2'
            '. image3 image3 image3 image3 . image2 '
            '. image3 image3 image3 image3 . . '
            '. . . image4 image4 image4 image4'
            '. . . image4 image4 image4 image4';
        `
      : css`
          grid-template-areas:
            'image1 image1 image1 image1  . . .'
            'image1 image1 image1 image1 . . image2 '
            '. image3 image3 image3 image3 . image2 '
            '. image3 image3 image3 image3 . . '
            '. . . image4 image4 image4 image4'
            'image5 image5 . image4 image4 image4 image4'
            'image5 image5 . . . . .';
        `}
  `}
`;

const ImageWrap = styled.div<{ index: number; image: DataGraphcms.Asset }>`
  display: block;

  background: transparent;

  background-image: url(${({ image }) => image.url});
  background-size: cover;
  background-position: center;

  grid-area: ${({ index }) => `image${index}`};
`;

export const ImageCollage = ({ images }: UIMedia.ImageCollageProps) => (
  <StyledImageCollage images={images}>
    {images.map((image, i) => (
      <ImageWrap index={i + 1} image={image} key={i} />
    ))}
  </StyledImageCollage>
);
