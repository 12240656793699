import React from 'react';

import { UIIcon } from '@klappir/types';

import { StyledIcon } from './shared';

export const NoIcon = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>no-icon</title>
    <path
      className="stroke"
      d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V13.5"
    />
    <path
      className="stroke"
      d="M12,16.5a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,16.5h0"
    />
    <rect
      className="stroke"
      x="0.75"
      y="0.75"
      width="22.5"
      height="22.5"
      rx="1.5"
      ry="1.5"
    />
  </StyledIcon>
);
