import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Linkedin = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>professional-network-linkedin</title>
      <path
        className="stroke"
        d="M5.67,22.63H.81V8.7H5.67Zm10.09-9.39a2.14,2.14,0,0,0-2.14,2.14v7.25H8.51V8.7h5.11v1.59a6.76,6.76,0,0,1,4.28-1.6c3.17,0,5.37,2.35,5.37,6.81v7.13H17.9V15.38A2.14,2.14,0,0,0,15.76,13.23Zm-10-9.36a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,5.74,3.88Z"
      />
    </svg>
  </StyledIcon>
);
