import { UIIcon } from '@klappir/types';

import { NoIcon } from '../NoIcon';
import { ArrowRightButtonIcon } from './ArrowRight';
import { FacebookIcon } from './Facebook';
import { ImproveSustainability } from './ImproveSustainability';
import { LinkedInIcon } from './LinkedIn';
import { QuoteIcon } from './Quote';
import { ReduceCarbonFootprint } from './ReduceCarbonFootprint';
import { ReduceCost } from './ReduceCost';
import { ReduceRisk } from './ReduceRisk';

export const KlappirIcon = ({ id, ...props }: UIIcon.IconProps) => {
  switch (id) {
    case 'reduce-risk':
      return <ReduceRisk {...props} />;

    case 'reduce-cost':
      return <ReduceCost {...props} />;

    case 'arrow-right':
      return <ArrowRightButtonIcon {...props} />;

    case 'reduce-carbon-footprint':
      return <ReduceCarbonFootprint {...props} />;

    case 'facebook':
      return <FacebookIcon {...props} />;

    case 'linkedin':
      return <LinkedInIcon {...props} />;

    case 'quote':
      return <QuoteIcon {...props} />;

    case 'improve-sustainability':
      return <ImproveSustainability {...props} />;

    default:
      return <NoIcon {...props} />;
  }
};
