import React from 'react';

import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Earth = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <circle className="stroke" cx="12" cy="12" r="11.25" />
    <path
      className="stroke"
      d="M9.289,22.921C7.767,20.689,6.75,16.633,6.75,12S7.767,3.311,9.289,1.079"
    />
    <line className="stroke" x1="0.75" y1="12" x2="23.25" y2="12" />
    <line className="stroke" x1="2.482" y1="18" x2="21.518" y2="18" />
    <line className="stroke" x1="2.482" y1="6" x2="21.518" y2="6" />
    <path
      className="stroke"
      d="M14.711,1.079C16.233,3.311,17.25,7.367,17.25,12s-1.017,8.689-2.539,10.921"
    />
  </StyledIcon>
);
