import { keyframes } from '@klappir/vendor/styled';

export const bounceRight = keyframes`
  0%, 50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(5%);
  }
  70% {
    transform: translateX(0px);
  }
  80% {
    transform: translateX(5%);
  }
  90% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`;

export const bounceLeft = keyframes`
  0%, 50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(-5%);
  }
  70% {
    transform: translateX(0px);
  }
  80% {
    transform: translateX(-5%);
  }
  90% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`;

export const bounceDown = keyframes`
  0%, 50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(5%);
  }
  70% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(5%);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`;

export const bounceUp = keyframes`
  0%, 50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-5%);
  }
  70% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(-5%);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`;
