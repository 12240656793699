import { UIIcon } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import styled, { css } from '@klappir/vendor/styled';

export const StyledIcon = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 24 24',
})<UIIcon.SVGProps>`
  ${({ color, width, height }) => css`
    ${width ? `width: ${width};` : ''}
    ${height ? `height: ${height};` : ''}

    .fill {
      fill: ${color ? getColor(color) : 'currentColor'};

      transition: 300ms fill;
    }

    .fill-secondary {
      ${color
        ? css`
            fill: ${getColor(color, 20)};
          `
        : css`
            fill: currentColor;
            opacity: 0.2;
          `}

      transition: 300ms fill;
    }

    .stroke {
      fill: none;
      stroke: ${color ? getColor(color) : 'currentColor'};
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5px;

      transition: 300ms stroke;
    }

    .thin {
      stroke-width: 1px;
    }
  `}
`;
