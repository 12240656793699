import { UIText } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { formatDate } from '@klappir/util/date';
import { fonts, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

export const Date = styled.span.attrs({ as: 'date' })<UIText.DateProps>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};

  font-family: ${fonts.primaryFont};
  font-weight: ${fonts.weight.regular};
  font-size: ${rem(14)};
  color: ${getColor('gray', 40)};
`;

export const FormattedDate = ({
  date,
  options,
  locale,
  children,
  ...props
}: UIText.DateProps) => {
  const formattedDate = formatDate(date || '', {
    locales: locale ?? 'en-gb',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...(options || {}),
  });

  return (
    <Date {...props}>
      {date && formattedDate}
      {children}
    </Date>
  );
};
