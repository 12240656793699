import { getColor } from '@klappir/ui/brand';
import { easings } from '@klappir/util/styles';
import styled, { css, keyframes } from '@klappir/vendor/styled';

const loadingBarAnimation = keyframes`
  0% {
    left: calc(-60% - 20px);
  }
  49% {
    left: calc(100% + 20px);
  }
  50% {
    left: calc(100% + 20px);
  }
  100% {
    left: calc(-60% - 20px);
  }
`;

export const LoadingBar = styled.div<{ visible?: boolean }>`
  height: 5px;
  width: 100%;

  transition: opacity 0.3s ${easings.easeOutCubic};

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  &:before {
    content: '';
    display: block;
    width: 60%;
    position: fixed;
    top: 0;
    left: calc(-60% - 20px);
    right: 0;
    height: 5px;
    background-color: ${getColor('green')};
    animation-name: ${loadingBarAnimation};
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ${easings.easeInOutCubic};
  }
`;
