import React from 'react';

import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const CheckMark = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    {/* TODO: Fix this icon */}
    <svg viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3262 0.789062L4.93555 11.1797L1.26172 7.50586C1.07617 7.35742 0.816406 7.35742 0.630859 7.50586L0.222656 7.95117C0.0371094 8.09961 0.0371094 8.39648 0.222656 8.58203L4.63867 12.998C4.78711 13.1465 5.08398 13.1465 5.23242 12.998L16.3652 1.86523C16.5508 1.67969 16.5508 1.38281 16.3652 1.23438L15.957 0.789062C15.7715 0.640625 15.5117 0.640625 15.3262 0.789062Z"
        fill="#0C8655"
      />
    </svg>
  </StyledIcon>
);
