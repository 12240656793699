import { UICore } from '@klappir/types';

export const gridConfig: UICore.Grid.Config = {
  maxWidth: '100%',
  // Number of columns
  columns: {
    tiny: 12,
    mobile: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    big: 12,
    huge: 12,
  },
  // Width in pixels
  gutterWidth: {
    tiny: 16,
    mobile: 16,
    sm: 16,
    md: 32,
    lg: 32,
    xl: 32,
    big: 32,
    huge: 32,
  },
  // Width in pixels
  paddingWidth: {
    tiny: 16,
    mobile: 16,
    sm: 16,
    md: 32,
    lg: 32,
    xl: 32,
    big: 32,
    huge: 32,
  },
};
