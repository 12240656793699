import React from 'react';

import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const ArrowUp = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>arrow-up</title>
    <path
      className="stroke bounce-up"
      d="M.75,17.189,11.47,6.47a.749.749,0,0,1,1.06,0L23.25,17.189"
    />
  </StyledIcon>
);

export const ArrowDown = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>arrow-down</title>
    <path
      className="stroke bounce-down"
      d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
    />
  </StyledIcon>
);

export const ArrowRight = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>arrow-right</title>
    <path
      className="stroke bounce-right"
      d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"
    />
  </StyledIcon>
);

export const ArrowLeft = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>arrow-left</title>
    <path
      className="stroke bounce-left"
      d="M16.25,23.25,5.53,12.53a.749.749,0,0,1,0-1.06L16.25.75"
    />
  </StyledIcon>
);
