import { boxSizingReset, media } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { Column } from './Column';
import { gridConfig } from './constants';
import { Row } from './Row';

export interface ContainerProps {
  /** removes the gutter */
  noGutter?: boolean;
  /** enables debugging */
  noMaxWidth?: boolean;
  /** enables debugging */
  debug?: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 24px;
  ${({ noMaxWidth }) => !noMaxWidth && `max-width: ${gridConfig.maxWidth};`};
  width: 100%;

  ${boxSizingReset}

  ${({ noGutter }) =>
    !noGutter &&
    css`
      ${media.map(
        (bp) => media[bp].above`
          padding-left: ${gridConfig.paddingWidth[bp]}px;
          padding-right: ${gridConfig.paddingWidth[bp]}px;
        `
      )}

      ${Row} {
        ${media.map(
          (bp) => media[bp].above`
            margin-left: -${gridConfig.gutterWidth[bp] / 2}px;
            margin-right: -${gridConfig.gutterWidth[bp] / 2}px;
          `
        )}
      }
      ${Column} {
        ${media.map(
          (bp) => media[bp].above`
            padding-left: ${gridConfig.gutterWidth[bp] / 2}px;
            padding-right: ${gridConfig.gutterWidth[bp] / 2}px;
          `
        )}
      }
    `}


  ${({ debug }) =>
    debug &&
    css`
      & {
        background-color: yellow;
        outline: red dashed 1px;
      }
      ${Row} {
        background-color: blue;
        outline: purple dashed 1px;
      }
      ${Column} {
        background-color: green;
        outline: white dashed 1px;
      }
    `}
`;
