import { stripUnit } from 'polished';

import { UtilStyles } from '@klappir/types';

import { breakpoints, breakpointsArray } from '../constants';
import { getViewport } from './getViewport';

export const getScreenClass = (): UtilStyles.Breakpoint => {
  const pxBreakpoints = Object.values(breakpoints).map((bp) =>
    stripUnit(bp.min)
  );
  const viewport = getViewport();
  if (viewport) {
    if (pxBreakpoints[5] && viewport >= pxBreakpoints[5]) {
      return breakpointsArray[5];
    } else if (pxBreakpoints[4] && viewport >= pxBreakpoints[4]) {
      return breakpointsArray[4];
    } else if (pxBreakpoints[3] && viewport >= pxBreakpoints[3]) {
      return breakpointsArray[3];
    } else if (pxBreakpoints[2] && viewport >= pxBreakpoints[2]) {
      return breakpointsArray[2];
    } else if (pxBreakpoints[1] && viewport >= pxBreakpoints[1]) {
      return breakpointsArray[1];
    }
  }
  return breakpointsArray[0];
};
