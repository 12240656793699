import React from 'react';

import { UIMedia } from '@klappir/types';
import styled from '@klappir/vendor/styled';

export const Image = styled.img<UIMedia.ImageProps>``;

// export const Image = ({ alt, ...props }: UIMedia.ImageProps) => (
//   <StyledImage alt={alt || ''} {...props} />
// );
