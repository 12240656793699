import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Facebook = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>social-media-facebook</title>
      <path
        className="stroke"
        d="M18.13,7.75H13.87V5.84a1,1,0,0,1,1-1.1h3v-4H13.54c-3.93,0-4.67,3-4.67,4.86V7.75h-3v4h3v11.5h5V11.75h3.85Z"
      />
    </svg>
  </StyledIcon>
);
