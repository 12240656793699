import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const ImproveSustainability = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <path d="M22.5,12.33a11,11,0,0,1-22,0Z" className="stroke thin" />
    <path
      d="M3,19.33H8.5a2,2,0,1,0,0-4,2,2,0,0,1-2-2v-1"
      className="stroke thin"
    />
    <path d="M21.3,17.33H17.5a2,2,0,0,0-2,2v3.25" className="stroke thin" />
    <path
      d="M23.28,8l-2-2.73c.66.52,1.61-.25.95-1.2L19.9.87a.5.5,0,0,0-.8,0L16.78,4c-.66,1,.29,1.72.95,1.2L15.72,8a.83.83,0,0,0,.41,1.37h6.74A.84.84,0,0,0,23.28,8Z"
      className="stroke thin"
    />
    <path
      d="M15,10.83,10.13,5.27a.49.49,0,0,0-.76,0L6.56,8.51"
      className="stroke thin"
    />
    <path
      d="M8.51,10.83l-3-3.56a.53.53,0,0,0-.37-.18.49.49,0,0,0-.38.17L1.58,10.83"
      className="stroke thin"
    />
  </StyledIcon>
);
