import {
  DependencyList,
  EffectCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { getScreenClass } from './getScreenClass';

interface MediaQueryObject {
  [key: string]: string | number | boolean;
}

const camelToHyphen = (str: string) =>
  str.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`).toLowerCase();

const objectToString = (query: string | MediaQueryObject) => {
  if (typeof query === 'string') {
    return query;
  }
  return Object.entries(query)
    .map(([feature, value]) => {
      feature = camelToHyphen(feature);
      if (typeof value === 'boolean') {
        return value ? feature : `not ${feature}`;
      }
      if (typeof value === 'number' && /[height|width]$/.test(feature)) {
        value = `${value}px`;
      }
      return `(${feature}: ${value})`;
    })
    .join(' and ');
};

type Effect = (effect: EffectCallback, inputs?: DependencyList) => void;
const createUseMedia =
  (effect: Effect) =>
  (rawQuery: string | MediaQueryObject, defaultState = false) => {
    // tslint:disable-next-line
    const [state, setState] = useState(defaultState);
    const query = objectToString(rawQuery);
    effect(() => {
      let mounted = true;
      let mql: MediaQueryList;

      const onChange = () => {
        if (!mounted) {
          return;
        }
        if (typeof window !== 'undefined') {
          setState(!!mql.matches);
        }
      };

      if (typeof window !== 'undefined') {
        mql = window.matchMedia(query);
        mql.addListener(onChange);
      }
      // TODO: fix 'used before assigned' ts error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setState(mql.matches);

      return () => {
        mounted = false;
        if (typeof window !== 'undefined') {
          mql.removeListener(onChange);
        }
      };
    }, [query]);

    return state;
  };

export const useMedia = createUseMedia(useEffect);
export const useMediaLayout = createUseMedia(useLayoutEffect);
export const useViewport = () => {
  const [viewport, setViewport] = useState('xs');

  useMemo(() => {
    setViewport(getScreenClass());
  }, []);

  return viewport;
};
