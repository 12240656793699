import React from 'react';

import { UIBrand, UIText } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { fonts, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { RenderMarkdown } from './RenderMarkdown';

export const QuoteMark = ({
  color,
  position,
  ...props
}: UIText.QuoteMarkProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 44 30"
    {...props}
  >
    {position === 'end' ? (
      <path
        fill={getColor(color || 'green', 20)}
        d="M12.3789 -2.58712e-06C6.33766 -3.11526e-06 3.71981 3.09999 3.71981 7.19999L3.71981 9.69999C3.71981 13.2 5.63286 16.2 9.96238 17C8.35139 22 5.12942 26 0.699216 30L10.0631 30C16.2049 25.3 21.0379 17.1 21.0379 9.3L21.0379 7.2C21.0379 3.09999 18.42 -2.05898e-06 12.3789 -2.58712e-06ZM36.0402 -5.18578e-07C29.999 -1.04672e-06 27.3811 3.1 27.3811 7.2L27.3811 9.7C27.3811 13.2 29.2942 16.2 33.6237 17C32.0127 22 28.7908 26 24.3605 30L33.7244 30C39.8663 25.3 44.6992 17.1 44.6992 9.3L44.6992 7.2C44.6992 3.1 42.0814 9.55933e-09 36.0402 -5.18578e-07Z"
      />
    ) : (
      <path
        fill={getColor(color || 'green', 20)}
        d="M32.1 30c6 0 8.6-3.1 8.6-7.2v-2.5c0-3.5-1.9-6.5-6.2-7.3 1.6-5 4.8-9 9.2-13h-9.3c-6.1 4.7-10.9 12.9-10.9 20.7v2.1c0 4.1 2.6 7.2 8.6 7.2zM8.6 30c6 0 8.6-3.1 8.6-7.2v-2.5c0-3.5-1.9-6.5-6.2-7.3 1.6-5 4.8-9 9.2-13h-9.3C4.8 4.7 0 12.9 0 20.7v2.1C0 26.9 2.6 30 8.6 30z"
      />
    )}
  </svg>
);

const QuoteBy = styled.div<UIText.QuoteProps>`
  margin-top: ${rem(24)};

  font-size: 65%;
  font-weight: ${fonts.weight.regular};

  ${({ color, size }) =>
    size === 'regular'
      ? css`
          &:before {
            content: '';
            margin: auto ${rem(8)} auto auto;

            display: inline-block;
            vertical-align: middle;
            width: ${rem(16)};
            height: ${rem(2)};

            border-radius: 3px;

            background: ${getColor(color || 'green', 40)};
          }
        `
      : css``}
`;

export const StyledQuote = styled.blockquote<
  Pick<UIText.QuoteProps, 'color' | 'size'>
>`
  ${({ color, size }) => css`
    max-width: ${rem(760)};
    margin: 0 auto;

    line-height: 1.75;
    font-size: ${rem(size === 'regular' ? 18 : 32)};
    font-weight: ${fonts.weight.regular};
    font-family: ${fonts.primaryFont};
    text-align: ${size === 'regular' ? 'left' : 'center'};

    svg {
      display: inline-block;
      width: 1.45em;

      position: relative;
      z-index: 0;

      &[data-position='start'] {
        top: -0.3em;
        margin-right: -0.45em;
        margin-left: -1em;
      }

      &[data-position='end'] {
        top: 0.6em;
        margin-right: 0;
        margin-right: -0.95em;
        margin-left: -0.5em;
      }
    }

    .text-block {
      position: relative;
      z-index: 1;
    }

    ${size !== 'regular' &&
    css`
      &:before,
      &:after {
        content: '';
        display: block;
        margin: ${rem(64)} auto;
        width: ${rem(40)};
        height: 4px;

        /* background-color: ${getColor('bloodorange', 40)}; */
        background: ${getColor(color || 'green', 40)};
      }
    `}
  `}
`;

export const Quote = ({
  children,
  by,
  hideQuotemark,
  color,
  size,
}: UIText.QuoteProps) => (
  <StyledQuote color={color} size={size}>
    {!hideQuotemark && (
      <QuoteMark color={color} position="start" data-position="start" />
    )}
    <RenderMarkdown className="text-block" forceInline>
      {children}
    </RenderMarkdown>
    {!hideQuotemark && (
      <QuoteMark color={color} position="end" data-position="end" />
    )}
    {by && (
      <QuoteBy color={color} size={size}>
        {by}
      </QuoteBy>
    )}
  </StyledQuote>
);
