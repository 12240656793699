import React, { useEffect, useState } from 'react';

import { UtilStyles } from '@klappir/types';
import { getScreenClass, mediaQuery } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const HiddenWrapper = styled.div`
  display: none;
`;

interface VisibleProps {
  tiny?: boolean;
  mobile?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  big?: boolean;
  huge?: boolean;
  coarse?: boolean;
  alwaysRender?: boolean;
  children?: React.ReactNode;
}

export const Visible: React.FC<VisibleProps> = ({
  tiny,
  mobile,
  sm,
  md,
  lg,
  xl,
  big,
  huge,
  coarse,
  alwaysRender,
  children,
}) => {
  const [screen, setScreen] = useState<UtilStyles.Breakpoint>('tiny');
  const [pointerCoarse, setPointerCoarse] = useState<boolean>(false);

  useEffect(() => {
    const updateScreen = () => {
      const currentScreen = getScreenClass();
      if (screen !== currentScreen) {
        setScreen(currentScreen);
      }
      const isCoarse = mediaQuery('(pointer: coarse)');
      if (pointerCoarse !== isCoarse) {
        setPointerCoarse(isCoarse);
      }
    };

    updateScreen();
    if (typeof window !== 'undefined') {
      window.addEventListener('orientationchange', updateScreen, false);
      window.addEventListener('resize', updateScreen, false);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('orientationchange', updateScreen);
        window.removeEventListener('resize', updateScreen);
      }
    };
  }, [screen, pointerCoarse]);

  const isVisible = (): boolean => {
    if (coarse && pointerCoarse) {
      return coarse;
    }
    switch (screen) {
      case 'tiny':
        return !!tiny;
      case 'mobile':
        return !!mobile;
      case 'sm':
        return !!sm;
      case 'md':
        return !!md;
      case 'lg':
        return !!lg;
      case 'xl':
        return !!xl;
      case 'big':
        return !!big;
      case 'huge':
        return !!huge;
      default:
        return false;
    }
  };

  return isVisible() ? (
    <>{children}</>
  ) : alwaysRender ? (
    <HiddenWrapper>{children}</HiddenWrapper>
  ) : null;
};

export default Visible;
