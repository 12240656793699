import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const ReduceCost = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <path
      d="M9.39129 11.5654H2.43478C2.19466 11.5654 2 11.7601 2 12.0002V13.7393C2 13.9795 2.19466 14.1741 2.43478 14.1741H9.39129C9.63141 14.1741 9.82607 13.9795 9.82607 13.7393V12.0002C9.82607 11.7601 9.63141 11.5654 9.39129 11.5654Z"
      className="stroke thin"
    />
    <path
      d="M10.2604 14.1738H3.30392C3.0638 14.1738 2.86914 14.3685 2.86914 14.6086V16.3477C2.86914 16.5879 3.0638 16.7825 3.30392 16.7825H10.2604C10.5006 16.7825 10.6952 16.5879 10.6952 16.3477V14.6086C10.6952 14.3685 10.5006 14.1738 10.2604 14.1738Z"
      className="stroke thin"
    />
    <path
      d="M9.39129 16.7832H2.43478C2.19466 16.7832 2 16.9779 2 17.218V18.9571C2 19.1972 2.19466 19.3919 2.43478 19.3919H9.39129C9.63141 19.3919 9.82607 19.1972 9.82607 18.9571V17.218C9.82607 16.9779 9.63141 16.7832 9.39129 16.7832Z"
      className="stroke thin"
    />
    <path
      d="M10.2604 19.3906H3.30392C3.0638 19.3906 2.86914 19.5853 2.86914 19.8254V21.5645C2.86914 21.8047 3.0638 21.9993 3.30392 21.9993H10.2604C10.5006 21.9993 10.6952 21.8047 10.6952 21.5645V19.8254C10.6952 19.5853 10.5006 19.3906 10.2604 19.3906Z"
      className="stroke thin"
    />
    <path
      d="M20.696 16.7832H13.7395C13.4993 16.7832 13.3047 16.9779 13.3047 17.218V18.9571C13.3047 19.1972 13.4993 19.3919 13.7395 19.3919H20.696C20.9361 19.3919 21.1308 19.1972 21.1308 18.9571V17.218C21.1308 16.9779 20.9361 16.7832 20.696 16.7832Z"
      className="stroke thin"
    />
    <path
      d="M19.8249 19.3906H12.8684C12.6283 19.3906 12.4336 19.5853 12.4336 19.8254V21.5645C12.4336 21.8047 12.6283 21.9993 12.8684 21.9993H19.8249C20.065 21.9993 20.2597 21.8047 20.2597 21.5645V19.8254C20.2597 19.5853 20.065 19.3906 19.8249 19.3906Z"
      className="stroke thin"
    />
    <path
      d="M2.43164 2L8.84119 8.40607C8.99249 8.55747 9.19431 8.64774 9.40802 8.65961C9.62173 8.67149 9.83231 8.60412 9.99945 8.47042L13.1142 5.97825C13.2847 5.84215 13.5001 5.77511 13.7177 5.79041C13.9353 5.80571 14.1392 5.90224 14.289 6.06086L21.1272 13.3043"
      className="stroke thin"
    />
    <path d="M15.043 13.3045H21.1299V7.65234" className="stroke thin" />
  </StyledIcon>
);
