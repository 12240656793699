import { UIText } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { fonts, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

export const Callout = styled.p<UIText.CalloutProps>`
  font-size: ${rem(24)};
  font-weight: ${fonts.weight.regular};
  font-family: ${fonts.primaryFont};
  line-height: 1.4;
  color: ${getColor('black')};

  b,
  strong {
    font-weight: ${fonts.weight.semibold};
  }

  i,
  em {
    font-style: italic;
  }
`;
