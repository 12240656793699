import React from 'react';

import { UIIcon } from '@klappir/types';

import { NoIcon } from '../NoIcon';
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp } from './Arrow';
import { CheckMark } from './CheckMark';
import { Earth } from './Earth';
import { Facebook } from './Facebook';
import { Linkedin } from './Linkedin';
import { Login } from './Login';
import { Logout } from './Logout';
import { PDF } from './Pdf';
import { Reddit } from './Reddit';
import { Twitter } from './Twitter';
import { Video } from './Video';

export const StreamlineIcon = ({ id, ...props }: UIIcon.IconProps) => {
  switch (id) {
    case 'arrow-up':
      return <ArrowUp {...props} />;

    case 'arrow-down':
      return <ArrowDown {...props} />;

    case 'arrow-left':
      return <ArrowLeft {...props} />;

    case 'arrow-right':
      return <ArrowRight {...props} />;

    case 'check-mark':
      return <CheckMark {...props} />;

    case 'earth':
      return <Earth {...props} />;

    case 'login':
      return <Login {...props} />;

    case 'logout':
      return <Logout {...props} />;

    case 'pdf':
      return <PDF {...props} />;

    case 'video':
      return <Video {...props} />;

    case 'facebook':
      return <Facebook {...props} />;

    case 'twitter':
      return <Twitter {...props} />;

    case 'linkedin':
      return <Linkedin {...props} />;

    case 'reddit':
      return <Reddit {...props} />;

    default:
      return <NoIcon {...props} />;
  }
};
