import React from 'react';

import { UIIcon } from '@klappir/types';

import { KlappirIcon } from './klappir';
import { StreamlineIcon } from './streamline';

export const Icon = ({ library, ...props }: UIIcon.IconProps) => {
  switch (library) {
    case 'klappir':
      return <KlappirIcon {...props} />;

    case 'streamline':
    default:
      return <StreamlineIcon {...props} />;
  }
};
