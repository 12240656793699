import { UICore } from '@klappir/types';
import { css } from '@klappir/vendor/styled';

const slantedBase: UICore.Slant.Fn = (color = 'inherit', deg = 2.5) => css`
  content: '';
  position: absolute;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background: ${color};

  transform: skewY(${deg}deg);
  z-index: -1;
`;

export const slantedTop: UICore.Slant.Fn = (color, deg) => css`
  margin-top: 10%;
  &:before {
    ${slantedBase(color, deg)}
    top: 0;
    transform-origin: 100% 0;
  }
`;

export const slantedBottom: UICore.Slant.Fn = (color, deg, pos = '0px') => css`
  margin-bottom: 10%;
  position: relative;
  &:after {
    ${slantedBase(color, deg)}
    bottom: ${pos};
    transform-origin: 0 100%;
  }
`;
