import { UtilStyles } from '@klappir/types';
import { media } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { Column } from './Column';

interface RowProps {
  /** reverse the direction of the row */
  reverse?: boolean | UtilStyles.Breakpoint[];
  reverseDirection?: 'row' | 'column';
  /** value for justify-content */
  justify?: 'left' | 'center' | 'right';
  /** value for align-items */
  align?:
    | 'normal'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'safe center'
    | 'unsafe center';
  /** removes the gutter */
  noGutter?: boolean;
  /** enables debugging */
  debug?: boolean;
}

export const Row = styled.div<RowProps>`
  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ align = 'normal', justify = 'center' }) => css`
    & {
      align-items: ${align};
      justify-content: ${justify === 'left'
        ? 'flex-start'
        : justify === 'right'
        ? 'flex-end'
        : justify};
    }
  `}

  ${({ noGutter }) =>
    noGutter &&
    css`
      & {
        margin-left: 0;
        margin-right: 0;
      }

      ${Column} {
        padding-left: 0;
        padding-right: 0;
      }
    `}

  ${({ reverse, reverseDirection = 'row' }) =>
    reverse &&
    (Array.isArray(reverse)
      ? media.map(
          (bp) => media[bp].above`
            flex-direction: ${
              reverse.includes(bp)
                ? `${reverseDirection}-reverse`
                : `${reverseDirection === 'row' ? 'column' : 'row'}`
            };
          `
        )
      : `flex-direction: ${reverseDirection}-reverse;`)}

  ${({ debug }) =>
    debug &&
    css`
      & {
        background-color: blue;
        outline: purple dashed 1px;
      }
      ${Column} {
        background-color: green;
        outline: white dashed 1px;
      }
    `};
`;
