import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const PDF = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>pdf</title>
    <path
      className="stroke"
      d="M5.246,23.248h-3a1.5,1.5,0,0,1-1.5-1.5V2.248a1.5,1.5,0,0,1,1.5-1.5H12.875a1.5,1.5,0,0,1,1.061.44l5.871,5.871a1.5,1.5,0,0,1,.439,1.061v4.628"
    />
    <path className="stroke" d="M20.246,8.248h-6a1.5,1.5,0,0,1-1.5-1.5v-6" />
    <line className="stroke" x1="8.246" y1="23.248" x2="8.246" y2="15.748" />
    <path className="stroke" d="M8.246,15.748H9a2.25,2.25,0,0,1,0,4.5h-.75" />
    <path
      className="stroke"
      d="M14.246,23.248a3,3,0,0,0,3-3v-1.5a3,3,0,0,0-3-3Z"
    />
    <path className="stroke" d="M20.246,23.248v-6a1.5,1.5,0,0,1,1.5-1.5h1.5" />
    <line className="stroke" x1="20.246" y1="20.248" x2="22.496" y2="20.248" />
  </StyledIcon>
);
