import { gql, useQuery } from '@klappir/data/graphql';

import { web } from '../client';

export const GET_LOGO_CLOUD = gql`
  query getLogoCloud($slug: String!) {
    logoCloud(where: { slug: $slug }) {
      slug
      companies {
        name
        logoId
        website
      }
    }
  }
`;

export const getLogoCloud = async (slug: string) => {
  const { data } = await web.query({
    query: GET_LOGO_CLOUD,
    variables: { slug },
  });

  return data?.logoCloud;
};

export const useLogoCloud = (slug: string) => {
  const { data } = useQuery(GET_LOGO_CLOUD, { variables: { slug } });

  return data?.logoCloud;
};
