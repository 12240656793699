import '@fontsource/ibm-plex-sans';

import { normalize } from 'polished';

import { boxSizingReset, fonts } from '@klappir/util/styles';
import { createGlobalStyle, css } from '@klappir/vendor/styled';

import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/600.css';
import '@fontsource/ibm-plex-sans/700.css';

export const GlobalStyles = createGlobalStyle`
  ${normalize()}

  html {
    ${boxSizingReset}
    ${fonts.baseStyles}
  }

  html,
  body {
    margin: 0;
    padding: 0;

    /* overflow-x: hidden; */
    width: 100%;
    min-height: 100vh;

    z-index: 1;

    #root, #app, #__next{
      width: 100%;
      min-height: 100vh;
      position: relative;
      z-index: 1;

      overflow: clip;
    }

    sup, .superscript {
      font-size: 0.6em;
      line-height: initial;
      position: unset;
      vertical-align: super;
    }

    ${({ theme }) => css`
      color: ${theme.text};
      background: ${theme.background};
    `}

    transition: background-color .5s, color .5s;
  }
`;
