import { gql, useQuery } from '@klappir/data/graphql';
import { DataGraphcms } from '@klappir/types';

import { web } from '../client';
import { resolveLocales } from '../util';

export const GET_PAGE = gql`
  query getPage($slug: String, $locales: [Locale!]!, $stage: Stage!) {
    pages(where: { slug: $slug }, locales: $locales, stage: $stage) {
      slug
      title
      navigationLabel
      localizations(includeCurrent: true) {
        locale
        slug
        title
      }
      seo {
        title
        description
        keywords
        noIndex
        image {
          alt
          url(
            transformation: {
              image: { resize: { width: 1200, height: 627, fit: crop } }
            }
          )
        }
      }
      blocks {
        __typename
        ... on BlockPricingCard {
          pricingPlanCards: pricingPlans {
            title
            subTitle
            planPrice
            currency
            extraInfo
            featuredPlanFeatureGroups {
              title
            }
            pricingCardButtonText
            ctaLink {
              ... on Page {
                id
                slug
              }
              ... on ExternalLink {
                id
                url
              }
            }
            showFeatures
            chargedPerUnit
          }
        }
        ... on BlockCardsWithLinks {
          cardsWithLinks {
            title
            content
            link
            index
          }
        }
        ... on BlockHeadingTextContentAndVideo {
          heading {
            title
            coloredPart
          }
          textContentTitle
          textContent
          videoUrl
        }
        ... on BlockBigHero {
          title
          emphasisWord
          heroContent
          buttonText
          buttonLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
          bigHeroBackgroundImage {
            alt
            url
          }
        }
        ... on BlockTwoCol {
          columTitle
          titleEmphasizeWord
          columnContent
          readMoreButtonLabel
          readMoreLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
          columnImage {
            alt
            url
          }
          imageColumnPlacement
        }
        ... on BlockTabs {
          tabSectionTitle
          tabOneLabel
          tabTwoLabel
          moreItemsUrl
          tabOneContent {
            ... on Article {
              id
              slug
              title
              published
              summary
            }
          }
          tabTwoContent {
            ... on Article {
              id
              slug
              title
              published
              summary
            }
          }
        }
        ... on BlockTextGrid {
          sectionHeading
          sectionHeadingEmphasizeWord
          sectionSubheading
          textGridItems {
            ... on TextGridItem {
              textGridLabel
              textGridContent
              applyEmphasizeStyling
            }
          }
        }
        ... on BlockCallOut {
          sectionTitle
          callOutItems {
            ... on CallOutItem {
              callOutTitle
              callOutSubtitle
              callOutSummary
              callOutButtonLabel
              backgroundColor {
                rgba {
                  r
                  g
                  b
                  a
                }
              }
              callOutImage {
                alt
                url
              }
              callOutReferredLocation {
                ... on Article {
                  id
                  slug
                }
                ... on Page {
                  id
                  slug
                }
                ... on ExternalLink {
                  id
                  url
                }
              }
            }
          }
        }
        ... on BlockTestimonailColumns {
          sectionTitle
          sectionTitleEmphasiseWord
          columnTestimonials {
            ... on BlockTestimonial {
              content
              image {
                alt
                url(
                  transformation: {
                    image: { resize: { width: 620, height: 450, fit: crop } }
                  }
                )
              }
              person {
                name
                role
                company {
                  name
                }
              }
            }
          }
        }
        ... on BlockHero {
          layout
          preTitle
          title
          description
          primaryButtonText
          primaryButtonLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
          secondaryButtonText
          secondaryButtonLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
          illustration
          animate
          image {
            alt
            url
          }
          videoUrl
          envelope
        }
        ... on BlockHubSpotForm {
          formId
          portalId
          region
        }
        ... on BlockContentHubSpotForm {
          formId
          portalId
          region
          contentTitle
          contentSubtitle
          contentBody
          contentImage {
            alt
            url(
              transformation: {
                image: { resize: { width: 600, height: 400, fit: crop } }
              }
            )
          }
        }
        ... on BlockText {
          preTitle
          title
          text
          center
          ctaLinkLabel
          ctaLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
        }
        ... on BlockQuote {
          quote
          person {
            name
            role
            company {
              name
            }
          }
        }
        ... on BlockLogoCloud {
          slug
          companies {
            name
            logoId
            website
          }
        }
        ... on BlockPerson {
          people {
            name
            role
            image {
              alt
              url(
                transformation: {
                  image: { resize: { width: 400, height: 400, fit: crop } }
                }
              )
            }
          }
        }
        ... on BlockImageCollage {
          images {
            alt
            url
          }
        }
        ... on BlockCustomerQuotesCard {
          text1
          number1
          numberText1
          companyLogo1
          text2
          number2
          numberText2
          companyLogo2
          text3
          number3
          numberText3
          companyLogo3
        }
        ... on BlockTextIllustration {
          title
          illustration
          image {
            alt
            url(
              transformation: {
                image: { resize: { width: 590, height: 490, fit: crop } }
              }
            )
          }
          illustrationPosition
          blockTexts {
            preTitle
            title
            text
            center
            ctaLink {
              ... on Article {
                id
                slug
              }
              ... on Page {
                id
                slug
              }
              ... on ExternalLink {
                id
                url
              }
            }
            ctaLinkLabel
          }
        }
        ... on BlockTestimonial {
          content
          image {
            alt
            url(
              transformation: {
                image: { resize: { width: 620, height: 450, fit: crop } }
              }
            )
          }
          person {
            name
            role
            company {
              name
            }
          }
        }
        ... on BlockTestimonialCarousel {
          blockTestimonials {
            content
            image {
              alt
              url(
                transformation: {
                  image: { resize: { width: 620, height: 450, fit: crop } }
                }
              )
            }
            person {
              name
              role
              company {
                name
              }
            }
          }
        }
        ... on BlockAccordion {
          title
          useBackground
          blocks {
            ... on BlockTextIllustration {
              title
              illustration
              image {
                alt
                url(
                  transformation: {
                    image: { resize: { width: 590, height: 490, fit: crop } }
                  }
                )
              }
              illustrationPosition
              blockTexts {
                title
                text
                ctaLink {
                  ... on Article {
                    id
                    slug
                  }
                  ... on Page {
                    id
                    slug
                  }
                  ... on ExternalLink {
                    id
                    url
                  }
                }
                ctaLinkLabel
              }
            }
            ... on BlockText {
              preTitle
              title
              text
              center
            }
            ... on BlockQuote {
              title
              quote
              person {
                name
                role
                company {
                  name
                }
              }
            }
          }
        }
        ... on BlockHeadingWithButtonLink {
          heading {
            title
            subtitle {
              title
              coloredPart
            }
            coloredPart
          }
          link {
            label
            link
          }
        }
        ... on BlockTextCardsAndAVideo {
          cards {
            title {
              title
              coloredPart
            }
            paragraph
            link {
              link
              label
            }
          }
          videoLink
        }
        ... on BlockCardWithBackground {
          card {
            title {
              title
              coloredPart
            }
            paragraph
            link {
              link
              label
            }
          }
        }
      }
    }
  }
`;

export const getPage = async (
  slug: string,
  locale?: string,
  stage: 'PUBLISHED' | 'DRAFT' = 'PUBLISHED'
) => {
  const { data } = await web.query({
    query: GET_PAGE,
    variables: {
      slug,
      locales: resolveLocales(locale),
      stage: stage,
    },
    fetchPolicy: 'network-only',
  });

  return data?.pages?.[0] as DataGraphcms.QueryResultPage;
};
export const usePage = (slug: string, locale?: string) => {
  const { data } = useQuery(GET_PAGE, {
    variables: { slug, locales: resolveLocales(locale) },
  });

  return data?.pages?.[0] as DataGraphcms.QueryResultPage;
};

export const getPages = async (locale?: string) => {
  const { data } = await web.query({
    query: GET_PAGE,
    variables: { locales: resolveLocales(locale) },
  });
  return data?.pages as DataGraphcms.QueryResultPage[];
};

export const usePages = (locale?: string) => {
  const { data } = useQuery(GET_PAGE, {
    variables: { locales: resolveLocales(locale) },
  });

  return data?.pages as DataGraphcms.QueryResultPage[];
};
