import { UtilStyles } from '@klappir/types';
import { css } from '@klappir/vendor/styled';

import { clamp } from './rem';

export const primaryFont = `"IBM Plex Sans", "Open Sans", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif`;
export const secondaryFont = `"Lexend Giga", ${primaryFont}`;

export const weight: UtilStyles.Weights = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
};

export const baseStyles = css`
  & {
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: no-common-ligatures;
    -moz-osx-font-smoothing: grayscale;

    letter-spacing: 0;
    line-height: 1.9375em;

    font-family: ${primaryFont};
    font-display: swap;
    font-weight: ${weight.regular};
    font-size: 18px;
    font-size: ${clamp(1440, 2560, 18, 24, false)};
  }
`;
