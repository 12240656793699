export const formatDate = (
  providedDate: string,
  {
    locales,
    ...options
  }: Intl.DateTimeFormatOptions & { locales?: string | string[] }
): string => {
  return new Date(providedDate).toLocaleDateString(locales ?? 'en-gb', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...(options || {}),
  });
};
