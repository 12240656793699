import { SVG } from '@svgdotjs/svg.js';

import { UtilSVG } from '@klappir/types';

import { random } from './random';
import { spline } from './spline';

export const generateLine: UtilSVG.GenerateLine = (node, props) => {
  const svg = SVG(node);
  const width = props?.width ?? 400;
  const height = props?.height ?? 100;
  const color = props.color;
  const steps = props?.steps ?? 3;
  const lineWidth = props?.lineWidth ?? 14;
  const linecap = props?.linecap ?? 'round';
  const offset = (props?.offsetFromCenter ?? 20) / 100;
  const range = [
    height / 2 - (height / 2) * offset,
    height / 2 + (height / 2) * offset,
  ];

  svg.viewbox(0, 0, width, height);
  const { width: actualWidth } = svg.viewbox();
  const stepSize = actualWidth / (steps + 2);

  svg.clear();

  const points = [];

  for (let x = 0; x <= actualWidth; x += stepSize) {
    const y = random(range[0], range[1]);
    points.push({ x, y });
  }

  svg
    .path(spline(points, 1, false))
    .stroke({
      color,
      width: lineWidth,
      linecap,
    })
    .fill('none');
};
