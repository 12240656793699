import { UIText } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { fonts, media, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

const StyledHeading = styled.h1<UIText.HeadingProps>`
  margin: 0 auto 0.5em;
  font-weight: ${fonts.weight.semibold};
  font-family: ${fonts.primaryFont};
  line-height: 1.3;

  b,
  strong {
    font-weight: ${fonts.weight.semibold};
  }

  i,
  em {
    font-style: italic;
  }

  ${({ bold }) => bold && `font-weight: ${fonts.weight.semibold};`}
  ${({ italic }) => italic && `font-style: italic;`}

  small {
    margin: 0 0 0.5em;
    display: block;
    font-size: ${rem(16)};
    font-weight: ${fonts.weight.bold};
    color: ${getColor('green')};
  }
`;

export const H1 = styled(StyledHeading).attrs({ as: 'h1' })`
  font-size: ${rem(64)};
  line-height: 1.25;

  ${media.mobile.below`
    font-size: ${rem(54)};
  `}
`;

export const H2 = styled(StyledHeading).attrs({ as: 'h2' })`
  font-size: ${rem(48)};

  ${media.mobile.below`
    font-size: ${rem(38)};
  `}
`;

export const H3 = styled(StyledHeading).attrs({ as: 'h3' })`
  font-size: ${rem(40)};
  ${({ bold }) =>
    css`
      font-weight: ${fonts.weight[bold ? 'semibold' : 'regular']};
    `}
  ${media.mobile.below`
    font-size: ${rem(28)};
  `}
`;

export const H4 = styled(StyledHeading).attrs({ as: 'h2' })`
  font-size: ${rem(32)};
  ${({ bold }) =>
    css`
      font-weight: ${fonts.weight[bold ? 'semibold' : 'regular']};
    `}
  ${media.mobile.below`
    font-size: ${rem(28)};
  `}
`;

export const H5 = styled(StyledHeading).attrs({ as: 'h5' })`
  font-size: ${rem(24)};
  ${({ bold }) =>
    css`
      font-weight: ${fonts.weight[bold ? 'semibold' : 'regular']};
    `}
  ${media.mobile.below`
    font-size: ${rem(20)};
  `}
`;

export const Heading = ({
  type,
  ...props
}: {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'date';
} & UIText.HeadingProps) => {
  switch (type) {
    case 'h2':
      return <H2 {...props} />;
    case 'h3':
      return <H3 {...props} />;
    case 'h4':
      return <H4 {...props} />;
    case 'h5':
      return <H5 {...props} />;
    case 'h1':
    default:
      return <H1 {...props} />;
  }
};
