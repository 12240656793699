import { ReactNode, useState } from 'react';

import { DataGraphcms } from '@klappir/types';

export const useCarousel = (
  items: Array<{ content: ReactNode; image?: DataGraphcms.Asset }>
) => {
  const [current, setCurrent] = useState<number>(0);

  const next = () => setCurrent((idx) => (idx + 1) % items.length);
  const prev = () =>
    setCurrent((idx) =>
      idx === 0 ? items.length - 1 : (idx - 1) % items.length
    );

  const goTo = (idx: number) =>
    idx > items.length - 1
      ? setCurrent(items.length - 1)
      : setCurrent(idx < 0 ? 0 : idx);

  return { current, next, prev, goTo, currentItem: items[current], items };
};
