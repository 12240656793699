import { UIIcon } from '@klappir/types';

export const ArrowRightButtonIcon = (props: UIIcon.SVGProps) => {
  const width = props.width ?? '24';
  const height = props.height ?? '24';
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 12.5H22.5"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 5.5L22.5 12.5L15.5 19.5"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
