import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Video = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <title>play video</title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M22.5,21.75a1.5,1.5,0,0,1-1.5,1.5H3a1.5,1.5,0,0,1-1.5-1.5V2.25A1.5,1.5,0,0,1,3,.75H18a1.5,1.5,0,0,1,1.047.426l3,2.883A1.5,1.5,0,0,1,22.5,5.133Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
      <path
        d="M10.238,16.41A.856.856,0,0,1,9,15.644V9.856a.856.856,0,0,1,1.238-.766l5.789,2.895a.855.855,0,0,1,0,1.53Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
    </g>
  </StyledIcon>
);
