export const random = (
  min: number | number[],
  max: number,
  clamp = false
): number => {
  if (Array.isArray(min)) {
    return min[random(0, min.length - 1, true)];
  } else {
    const val = Math.random() * (max - min) + min;

    return clamp ? Math.round(val) : val;
  }
};
