import * as styledComponents from 'styled-components';

import { UITheme } from '@klappir/types';

const {
  createGlobalStyle,
  css,
  default: styled,
  isStyledComponent,
  keyframes,
  ServerStyleSheet,
  StyleSheetManager,
  // NOTE: Type issues, but we're not really using it for now
  // ThemeConsumer,
  // ThemeContext,
  ThemeProvider,
  useTheme,
  withTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<UITheme.Interface>;

export {
  createGlobalStyle,
  css,
  isStyledComponent,
  keyframes,
  ServerStyleSheet,
  StyleSheetManager,
  // ThemeConsumer,
  // ThemeContext,
  ThemeProvider,
  useTheme,
  withTheme,
};

export default styled;
