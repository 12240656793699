import { gql, useQuery } from '@klappir/data/graphql';
import { DataGraphcms } from '@klappir/types';

import { web } from '../client';
import { resolveLocales } from '../util';

export const GET_ARTICLES = gql`
  query getArticles($locales: [Locale!]!) {
    articles(orderBy: published_DESC, locales: $locales) {
      localizations(includeCurrent: true) {
        locale
        slug
        title
      }
      slug
      title
      published
      summary
      thumbnail: image {
        url(
          transformation: {
            image: { resize: { width: 350, height: 220, fit: crop } }
          }
        )
      }
      image {
        url(
          transformation: {
            image: { resize: { width: 830, height: 520, fit: crop } }
          }
        )
      }
      authors {
        name
      }
      category
      seo {
        title
        description
        keywords
        noIndex
        image {
          url(
            transformation: {
              image: { resize: { width: 1200, height: 627, fit: crop } }
            }
          )
        }
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  query getArticle($slug: String, $locales: [Locale!]!) {
    articles(where: { slug: $slug }, locales: $locales) {
      localizations(includeCurrent: true) {
        locale
        slug
        title
      }
      slug
      title
      published
      summary
      thumbnail: image {
        url(
          transformation: {
            image: { resize: { width: 350, height: 220, fit: crop } }
          }
        )
      }
      image {
        url(
          transformation: {
            image: { resize: { width: 830, height: 520, fit: crop } }
          }
        )
      }
      authors {
        name
        image {
          url
        }
      }
      category
      seo {
        title
        description
        keywords
        noIndex
        image {
          url(
            transformation: {
              image: { resize: { width: 1200, height: 627, fit: crop } }
            }
          )
        }
      }
      blocks {
        __typename
        ... on BlockHero {
          layout
          title
          description
          primaryButtonText
          primaryButtonLink {
            __typename
          }
          secondaryButtonText
          secondaryButtonLink {
            __typename
          }
          illustration
          image {
            url(
              transformation: {
                image: { resize: { width: 1445, height: 487, fit: crop } }
              }
            )
          }
        }
        ... on BlockHubSpotForm {
          formId
          portalId
          region
        }
        ... on BlockText {
          title
          text
          ctaLinkLabel
          ctaLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
        }
        ... on BlockQuote {
          quote
          person {
            name
            company {
              name
            }
          }
        }
        ... on BlockLogoCloud {
          slug
          companies {
            name
            logoId
            website
          }
        }
        ... on BlockPerson {
          people {
            name
            role
            image {
              url(
                transformation: {
                  image: { resize: { width: 400, height: 400, fit: crop } }
                }
              )
            }
          }
        }
      }
    }
  }
`;

export const GET_SUCCESS_ARTICLE = gql`
  query getArticles($locales: [Locale!]!, $articleType: ArticleCategory!) {
    articles(
      orderBy: published_DESC
      locales: $locales
      where: { category: $articleType }
    ) {
      localizations(includeCurrent: true) {
        locale
        slug
        title
      }
      slug
      title
      published
      summary
      thumbnail: image {
        url(
          transformation: {
            image: { resize: { width: 350, height: 220, fit: crop } }
          }
        )
      }
      image {
        url(
          transformation: {
            image: { resize: { width: 830, height: 520, fit: crop } }
          }
        )
      }
      authors {
        name
      }
      category
      seo {
        title
        description
        keywords
        noIndex
        image {
          url(
            transformation: {
              image: { resize: { width: 1200, height: 627, fit: crop } }
            }
          )
        }
      }
    }
  }
`;

export const getArticle = async (slug: string, locale?: string) => {
  const { data } = await web.query({
    query: GET_ARTICLE,
    variables: { slug, locales: resolveLocales(locale) },
  });

  return data?.articles?.[0] as DataGraphcms.QueryResultArticle;
};

export const useArticle = (slug: string, locale?: string) => {
  const { data } = useQuery(GET_ARTICLE, {
    variables: { slug, locales: resolveLocales(locale) },
  });

  return data?.articles?.[0] as DataGraphcms.QueryResultArticle;
};

export const getArticles = async (locale?: string) => {
  const { data } = await web.query({
    query: GET_ARTICLES,
    variables: { locales: resolveLocales(locale) },
  });

  return data?.articles as DataGraphcms.QueryResultArticle[];
};

export const getUserSuccessArticles = async (
  locale?: string,
  category?: string
) => {
  const { data } = await web.query({
    query: GET_SUCCESS_ARTICLE,
    variables: { locales: resolveLocales(locale), articleType: category },
  });

  return data?.articles as DataGraphcms.QueryResultArticle[];
};

export const useArticles = (locale?: string) => {
  const { data } = useQuery(GET_ARTICLES, {
    variables: { locales: resolveLocales(locale) },
  });

  return data?.articles as DataGraphcms.QueryResultArticle[];
};
