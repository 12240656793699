import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';

export const GoogleAnalyticsProvider: React.FC<{ trackingId?: string }> = ({
  trackingId,
  ...props
}) => {
  return !trackingId ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{props.children}</>
  ) : (
    <AnalyticsProvider
      value={Analytics({
        app: 'klappir-web',
        plugins: [googleAnalytics({ trackingId })],
      })}
      {...props}
    />
  );
};
