import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { useEffect, useState } from 'react';

import { pageview } from '@klappir/analytics/google';
import { client, useGlobals } from '@klappir/data/graphcms';
import { ApolloProvider } from '@klappir/data/graphql';
import { getColor, Line } from '@klappir/ui/brand';
import { CookieBanner } from '@klappir/ui/cookie-banner';
import { GlobalMain, GlobalStyles, GridOverlay } from '@klappir/ui/core';
import { LoadingBar } from '@klappir/ui/loading';
import { theme } from '@klappir/ui/theme';
import { ThemeProvider } from '@klappir/vendor/styled';

const AppContents = ({
  Component,
  pageProps,
  loading,
  debugMode,
}: AppProps & { loading?: boolean; debugMode?: boolean }) => {
  const locale = 'en';

  const globalData = useGlobals(locale);

  let bannerProps = {
    cookieBannerText: '',
    cookieBannerAcceptButtonText: '',
    cookieBannerDeclineButtonText: '',
  };

  if (globalData) {
    bannerProps = {
      cookieBannerText: globalData.cookieBannerText,
      cookieBannerAcceptButtonText: globalData.cookieBannerAcceptButtonText,
      cookieBannerDeclineButtonText: globalData.cookieBannerDeclineButtonText,
    };
  }

  return (
    <>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe
              title="google-tag-manager-digido"
              src="https://www.googletagmanager.com/ns.html?id=GTM-WF5PB2G"
              height="0"
              width="0"
              style={'display:none;visibility:hidden'}
            ></iframe>`,
        }}
      ></noscript>
      <Script
        id="lead-forensics"
        strategy="afterInteractive"
        src="https://secure.agile-enterprise-365.com/js/780908.js"
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <img alt="" src=https://secure.agile-enterprise-365.com/780908.png style="display:none;" />`,
        }}
      ></noscript>
      <Line
        className="line"
        style={{
          margin: 'auto',
          width: '150vmax',
          position: 'absolute',
          top: '0',
          left: '50%',
          zIndex: -1,
          transform: 'translateX(-50%) rotate(-40deg)',
          opacity: 0.3,
        }}
        offsetFromCenter={40}
        customColor={getColor('orange', 20)}
      />
      <GlobalStyles />
      <LoadingBar visible={loading} />
      <GlobalMain loading={loading}>
        <Component {...pageProps} />
      </GlobalMain>
      {debugMode && <GridOverlay />}
      <CookieBanner {...bannerProps} />
    </>
  );
};

function CustomApp(props: AppProps) {
  const [loading, setLoading] = useState(false);

  const { router } = props;

  useEffect(() => {
    datadogLogs.init({
      clientToken: process?.env?.NEXT_PUBLIC_DD_TOKEN ?? '',
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      silentMultipleInit: true,
    });

    datadogRum.init({
      applicationId: process?.env?.NEXT_PUBLIC_DD_APP_ID ?? '',
      clientToken: process?.env?.NEXT_PUBLIC_DD_TOKEN ?? '',
      site: 'datadoghq.eu',
      service: 'fe_web',
      env: 'production',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
      setLoading(
        router.locale === 'en'
          ? url !== router.asPath && url !== `/${router.locale}${router.asPath}`
          : url !== `/${router.locale}` &&
              url !== `/${router.locale}${router.asPath}`
      );
    };
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', handleRouteChange);
    };
  }, [router.events, router.asPath, router.locale]);

  const debugMode = !!router?.query?.debug ?? false;

  return (
    <>
      <DefaultSeo
        title="Klappir - The smart way to sustainability"
        description="Reduce your negative environmental impact, costs and risks"
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://www.klappir.com',
          site_name: 'Klappir',
        }}
      />
      <Head>
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WF5PB2G');
        `}
        </Script>
        <meta name="theme-color" content={getColor('green')} />
        <link href="/favicon.ico" rel="icon" type="image/x-icon" />
        <link
          href="/favicon.ico"
          rel="shortcut icon"
          sizes="16x16 32x32 48x48"
        />
        <link href="/favicon/favicon-48.png" rel="icon" type="image/png" />

        <link
          href="/favicon/favicon.svg"
          rel="mask-icon"
          color={getColor('green')}
        />

        <link
          href="/favicon/favicon-16.png"
          sizes="16x16"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-32.png"
          sizes="32x32"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-48.png"
          sizes="48x48"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-64.png"
          sizes="64x64"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-120.png"
          sizes="120x120"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-152.png"
          sizes="152x152"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-167.png"
          sizes="167x167"
          rel="apple-touch-icon-precomposed"
        />
        <link
          href="/favicon/favicon-180.png"
          sizes="180x180"
          rel="apple-touch-icon-precomposed"
        />
      </Head>
      <Script id="google-tag-manager-digido" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WF5PB2G');
        `}
      </Script>
      <ApolloProvider client={client.web}>
        <ThemeProvider theme={theme.light}>
          <AppContents
            {...props}
            loading={loading ? loading : undefined}
            debugMode={debugMode}
          />
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
}

export default CustomApp;
