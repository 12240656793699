import styled, { css } from '@klappir/vendor/styled';

export const screenReaderOnly = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const ScreenReaderOnly = styled.span`
  ${screenReaderOnly}
`;
