import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const ReduceCarbonFootprint = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <path
      d="M15.0267 8.43528C13.9006 9.76137 13.3059 11.5048 15.9902 12.6744C17.2189 15.4179 19.0867 14.4022 20.3302 12.9353C21.4737 11.5875 21.9676 8.16484 21.1885 6.01354C21.1658 5.95091 21.1291 5.89433 21.0811 5.84814C21.0332 5.80195 20.9752 5.7674 20.9118 5.74715C20.8484 5.7269 20.7811 5.7215 20.7153 5.73135C20.6494 5.7412 20.5867 5.76605 20.5319 5.80397C18.5963 7.1405 16.3363 6.88832 15.0267 8.43528Z"
      className="stroke thin"
    />
    <path
      d="M13.7402 17.5868C14.658 14.1254 16.5478 10.9993 19.1863 8.57812"
      className="stroke thin"
    />
    <path
      d="M11.9992 22C10.0732 21.3539 8.55664 17.0748 8.55664 12C8.55664 6.92522 10.0758 2.65043 11.9992 2"
      className="stroke thin"
    />
    <path
      d="M11.9982 22C8.25034 21.4878 5.05469 17.1774 5.05469 12C5.05469 6.9087 8.34077 2.62522 11.9982 2"
      className="stroke thin"
    />
    <path d="M3.27734 7.2168H12.4339" className="stroke thin" />
    <path d="M2 11.5654H12.4348" className="stroke thin" />
    <path d="M2.87109 15.9131H12.4363" className="stroke thin" />
    <path
      d="M12.4348 2.02174C12.2896 2.01565 12.147 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22C12.147 22 12.2896 21.9843 12.4348 21.9783V2.02174Z"
      className="stroke thin"
    />
  </StyledIcon>
);
