import React from 'react';

import { UIText } from '@klappir/types';

import { Callout } from './Callout';
import { Code } from './Code';
import { H1, H2, H3, H4, H5 } from './Heading';
import { Paragraph } from './Paragraph';
import { Quote } from './Quote';

export const Text = ({ variant, ...props }: UIText.TextProps) => {
  switch (variant) {
    case 'h1':
      return <H1 {...props} />;
    case 'h2':
      return <H2 {...props} />;
    case 'h3':
      return <H3 {...props} />;
    case 'h4':
      return <H4 {...props} />;
    case 'h5':
      return <H5 {...props} />;
    case 'quote':
      return <Quote {...props} children={props.children as string} />;
    case 'callout':
      return <Callout {...props} />;
    case 'code':
      return <Code {...props} />;
    case 'paragraph':
    default:
      return <Paragraph {...props} />;
  }
};
