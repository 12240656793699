import { rem as remPolished, stripUnit } from 'polished';

export const rembase = 18;

export const getRem = () => {
  if (typeof document === 'undefined') {
    return rembase;
  }
  const root = document.querySelector('html');
  return root ? Number(getComputedStyle(root).fontSize.slice(0, -2)) : rembase;
};

export function remToPX(value: string | number = 1): string {
  return `${Number(stripUnit(value)) * rembase}px`;
}

/**
 * Takes value in pixels and returns the rem value relative to getRem.
 *
 * Default value is 1rem
 */
export const rem = (value: string | number): string =>
  remPolished(`${stripUnit(value)}px`, rembase);

export const clamp = (
  minWidthPx: number,
  maxWidthPx: number,
  minSize: number,
  maxSize: number,
  useRem = true
) => {
  const minWidth = minWidthPx;
  const maxWidth = maxWidthPx;

  const slope = (maxSize - minSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minSize;

  const min = useRem ? rem(minSize) : `${minSize}px`;
  const max = useRem ? rem(maxSize) : `${maxSize}px`;
  const val = `${yAxisIntersection}px + ${slope * 100}vw`;

  return `clamp(${min},${val},${max})`;
};
