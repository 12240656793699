import Head from 'next/head';
import { NextSeo, NextSeoProps } from 'next-seo';

import { UICore } from '@klappir/types';

export const SEO = ({
  title,
  description,
  keywords,
  noIndex,
  image,
  canonical,
}: UICore.SEOProps) => {
  const seoProps: NextSeoProps = {
    title: title ?? 'Klappir - The smart way to sustainability',
    description:
      description ??
      'Reduce your negative environmental impact, costs and risks',
    noindex: noIndex ?? false,
  };

  return (
    <>
      <NextSeo
        {...seoProps}
        canonical={canonical}
        openGraph={{
          title: seoProps.title,
          description: seoProps.description,
          images: image?.url
            ? [
                {
                  url: image.url,
                  width: 1200,
                  height: 627,
                  alt: seoProps.title,
                },
              ]
            : [],
        }}
      />
      {keywords?.length > 0 && (
        <Head>
          <meta name="keywords" content={keywords.join(',')} />
        </Head>
      )}
    </>
  );
};
