/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UtilStyles } from '@klappir/types';
import { css } from '@klappir/vendor/styled';

import { breakpoints, breakpointsArray } from '../constants';
export { getScreenClass } from './getScreenClass';
export { getViewport } from './getViewport';
export { useMedia, useMediaLayout, useViewport } from './hooks';
export { mediaQuery } from './mediaQuery';

// TODO: Figure out a way to define the query template
type QueryTemplate = any;

interface SingleTpl {
  above: QueryTemplate;
  below: QueryTemplate;
  only: QueryTemplate;
}

type MediaTpl = { [key in UtilStyles.Breakpoint]: SingleTpl };

const queryFactory: QueryTemplate =
  (queries: string[]) =>
  (...value: any[]) => {
    const foo = queries
      .map((query: string | string[]) => {
        if (Array.isArray(query)) {
          return query.map((q) => `(${q})`).join(' and ');
        }
        return `(${query})`;
      })
      .join(', ');

    return css`
      @media ${foo} {
        ${
          // NOTE: ts-ignore because typescript ignores all array validations here
          // @ts-ignore
          css(...value)
        }
      }
    `;
  };

// NOTE: ts-ignore because how typescript infers types and values in this code block
// @ts-ignore
const mediaBase: MediaTpl = (breakpointsArray || []).reduce((acc, label) => {
  // @ts-ignore
  acc[label] = {
    above: queryFactory([`min-width: ${breakpoints[label].min}`]),
    below: queryFactory([`max-width: ${breakpoints[label].max}`]),
    only: queryFactory([
      [
        `min-width: ${breakpoints[label].min}`,
        `max-width: ${breakpoints[label].max}`,
      ],
    ]),
  };
  return acc;
}, {});

const between = (min: UtilStyles.Breakpoint, max: UtilStyles.Breakpoint) => {
  return queryFactory([
    [
      `min-width: ${breakpoints[min].min}`,
      `max-width: ${breakpoints[max].max}`,
    ],
  ]);
};

const coarse: SingleTpl & { between: QueryTemplate; raw: QueryTemplate } = {
  above: (above: UtilStyles.Breakpoint) =>
    queryFactory(['pointer: coarse', `min-width: ${breakpoints[above].min}`]),
  below: (below: UtilStyles.Breakpoint) =>
    queryFactory(['pointer: coarse', `max-width: ${breakpoints[below].max}`]),
  only: (breakpoint: UtilStyles.Breakpoint) =>
    queryFactory([
      'pointer: coarse',
      [
        `min-width: ${breakpoints[breakpoint].min}`,
        `max-width: ${breakpoints[breakpoint].max}`,
      ],
    ]),
  between: (min: UtilStyles.Breakpoint, max: UtilStyles.Breakpoint) =>
    queryFactory([
      'pointer: coarse',
      [
        `min-width: ${breakpoints[min].min}`,
        `max-width: ${breakpoints[max].max}`,
      ],
    ]),
  raw: queryFactory(['pointer: coarse']),
};

const map = (callback: (breakpoint: UtilStyles.Breakpoint) => any) =>
  breakpointsArray.map((bp) => callback(bp));

export const media = {
  ...mediaBase,
  between,
  coarse,
  map,
  queryFactory,
};
