import { UIIcon } from '@klappir/types';

export const FacebookIcon = (props: UIIcon.SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_194_426)">
        <path
          d="M19.55 14.56C19.5355 14.6803 19.4778 14.7911 19.3877 14.8721C19.2975 14.953 19.1811 14.9985 19.06 15H17C16.8682 15.0026 16.7425 15.0561 16.6493 15.1493C16.5561 15.2425 16.5026 15.3682 16.5 15.5V23.5C16.5 23.6326 16.5527 23.7598 16.6464 23.8536C16.7402 23.9473 16.8674 24 17 24H23C23.2652 24 23.5196 23.8946 23.7071 23.7071C23.8946 23.5196 24 23.2652 24 23V1C24 0.734784 23.8946 0.48043 23.7071 0.292893C23.5196 0.105357 23.2652 0 23 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24H12C12.1318 23.9974 12.2575 23.9439 12.3507 23.8507C12.4439 23.7575 12.4974 23.6318 12.5 23.5V15.5C12.5 15.3674 12.4473 15.2402 12.3536 15.1464C12.2598 15.0527 12.1326 15 12 15H10C9.86739 15 9.74021 14.9473 9.64645 14.8536C9.55268 14.7598 9.5 14.6326 9.5 14.5V11.5C9.5 11.3674 9.55268 11.2402 9.64645 11.1464C9.74021 11.0527 9.86739 11 10 11H12C12.1326 11 12.2598 10.9473 12.3536 10.8536C12.4473 10.7598 12.5 10.6326 12.5 10.5V9.19C12.5 7.68092 13.0995 6.23364 14.1666 5.16656C15.2336 4.09948 16.6809 3.5 18.19 3.5H19.5C19.6326 3.5 19.7598 3.55268 19.8536 3.64645C19.9473 3.74021 20 3.86739 20 4V7C20 7.13261 19.9473 7.25979 19.8536 7.35355C19.7598 7.44732 19.6326 7.5 19.5 7.5H18.19C17.7418 7.5 17.3119 7.67805 16.995 7.99499C16.6781 8.31193 16.5 8.74178 16.5 9.19V10.5C16.5 10.6326 16.5527 10.7598 16.6464 10.8536C16.7402 10.9473 16.8674 11 17 11H19.43C19.5018 10.9994 19.573 11.0142 19.6385 11.0436C19.7041 11.0729 19.7626 11.116 19.81 11.17C19.8558 11.2232 19.8901 11.2854 19.9107 11.3525C19.9314 11.4196 19.9379 11.4902 19.93 11.56L19.55 14.56Z"
          fill="#3C9E76"
        />
      </g>
      <defs>
        <clipPath id="clip0_194_426">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
