import { UIIcon } from '@klappir/types';

import { StyledIcon } from '../shared';

export const Reddit = (props: UIIcon.SVGProps) => (
  <StyledIcon {...props}>
    <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>social-media-reddit</title>
      <ellipse className="stroke" cx="12" cy="15.33" rx="9.29" ry="6.36" />
      <path
        className="stroke"
        d="M15.46,18.15A7,7,0,0,1,12,19a7,7,0,0,1-3.48-.87"
      />
      <path
        className="stroke"
        d="M16.66,13.87a.75.75,0,1,1-.75-.75.75.75,0,0,1,.75.75"
      />
      <path
        className="stroke"
        d="M8.84,13.87a.75.75,0,1,1-.75-.75.75.75,0,0,1,.75.75"
      />
      <path className="stroke" d="M18.6,10.85a2.45,2.45,0,1,1,2.58,3.47" />
      <circle className="stroke" cx="19.53" cy="4.46" r="2.15" />
      <path className="stroke" d="M5.4,10.86a2.45,2.45,0,1,0-2.58,3.47" />
      <path className="stroke" d="M12,9s0-6.67,5.38-4.91" />
    </svg>
  </StyledIcon>
);
