import { mix, transparentize } from 'polished';

import { UIBrand } from '@klappir/types';

export const colors: UIBrand.Colors = {
  // primary colors
  green: '#3C9E77',
  darkgreen: '#00261B',
  bloodorange: '#FF775F',
  // additional colors
  black: '#000',
  gray: '#191919',
  white: '#FFF',
  orange: '#EF8F1D',
  yellow: '#F2E64E',
  turquoise: '#42E2E2',
  blue: '#208CF7',
  purple: '#6251B7',
  error: '#BA181B',
  lightgreen: '#cee7db',
};

export const resolveColor = (value: string): string => {
  const defaultValue = colors.green;
  if (!value) {
    return defaultValue;
  }

  return value.includes('#')
    ? value
    : Object.hasOwnProperty.call(colors, value)
    ? colors[value]
    : defaultValue;
};

export const getColor: UIBrand.GetColor = (color, variant = 100, opacity) => {
  const resolvedColor = resolveColor(color);
  if (opacity) {
    return transparentize((100 - opacity) / 100, resolvedColor);
  }
  if (!variant) {
    return resolvedColor;
  }
  switch (color) {
    case 'black':
    case 'white':
      return resolvedColor;

    default:
      return mix(variant / 100, resolvedColor, '#fff');
  }
};
