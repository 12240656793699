import { UICore } from '@klappir/types';
import styled, { css } from '@klappir/vendor/styled';

export const GlobalMain = styled.main.attrs({
  id: 'mainContent',
})<UICore.GlobalMainProps>`
  display: block;
  position: relative;
  margin: 0;

  padding-top: 100px;

  width: 100%;
  z-index: 1;

  ${({ theme }) => css`
    color: ${theme.text};
  `}

  ${({ loading }) =>
    loading
      ? css`
          *,
          *::before,
          *::after {
            cursor: progress !important;
          }
        `
      : ''}

  ::after {
    content: '';
    clear: both;
  }
`;
